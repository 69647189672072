export const parseStringify = (value: any) => JSON.parse(JSON.stringify(value));
export function encryptKey(passkey: string) {
  return btoa(passkey);
}
export function decryptKey(passkey: string) {
  return atob(passkey);
}

/**
 * Replaces content inside curly braces with a templated pattern
 * Example:
 * replaceBracedContent("Hello $0!", "This is {some text}") => "Hello some text!"
 * The pattern should contain $0 which will be replaced with the content inside curly braces
 * If no pattern is provided, returns the original text unchanged
 */
export function replaceBracedContent(pattern: string, text: string) {
  if (!pattern) {
    return text;
  }

  return text.replace(/\{([^{}]+)\}/g, (match, content) => {
    return pattern.replace(/\$0/, content);
  });
}

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

/**
 * Converts enum keys from SCREAMING_SNAKE_CASE to Title Case
 * Example: SEASONAL_SOFTCORE -> Seasonal Softcore
 * Example: SOME_ENUM_KEY -> Some Enum Key
 * @param enumKey The enum key to format
 * @returns The formatted string in Title Case
 */
export function formatEnumKeysToTitleCase(enumKey: string) {
  return enumKey
    .toLowerCase() // First convert to lowercase
    .split("_") // Split on underscores
    .map((word) => capitalize(word)) // Capitalize each word
    .join(" ") // Join with spaces
    .trim();
}
