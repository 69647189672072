"use client";

import Link from "next/link";
import { Image } from "@repo/ui/image";
import { signInWithTimezone } from "@/lib/auth";
import { ItemCard } from "@repo/ui/item-card";
import SancuarySVG from "@/../public/sanctuary.svg";

export default function LoggedOut() {
  const signIn = () => {
    signInWithTimezone();
  };

  return (
    <div className="grid  gap-10">
      <div className="flex flex-col justify-center gap-4">
        <div
          role="heading"
          aria-level={2}
          className="flex flex-col items-center justify-center  gap-2 text-2xl uppercase sm:flex-row sm:gap-4"
        >
          Welcome to
          <span className="flex items-center">
            <div className="relative h-12 w-12">
              <Image
                alt="Diablo.trade"
                fill
                src="/assets/logos/sanctuary.png"
              />
            </div>
            <div className="leading-0 ml-2 uppercase">
              <div className="relative h-24 w-24">
                <Image alt="Diablo.trade" fill src="/sanctuary.svg" />
              </div>
            </div>
          </span>
        </div>
        <h3 className="mx-auto  max-w-2xl px-8 text-center">
          Diablo.Trade is a player-to-player marketplace for Diablo 4 that
          allows players to exchange a variety of goods and services.
        </h3>
        <div className="flex flex-col items-center justify-center gap-4 sm:flex-row">
          <button
            className="flex h-[40px] w-[210px] items-center justify-center text-lg  uppercase hover:scale-105 hover:shadow-md"
            onClick={() => {
              signIn();
            }}
            style={{
              borderImage:
                "url('/assets/buttons/v4/button-border-blue.svg') stretch",
              borderImageWidth: "auto",
              borderImageSlice: "110 0 0 0",
            }}
            type="button"
          >
            sign up/login
          </button>
        </div>
      </div>

      <div className=" grid gap-4">
        <div className="mx-auto flex items-center  justify-center gap-2 sm:w-[550px] sm:flex-row  lg:w-[820px]">
          <h3 className="text-xl font-bold">Recent Listings</h3>
        </div>
        <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
          {Array.from({ length: 6 }).map((_, index) => (
            <ItemCard
              key={index}
              title="Title(MOCKED)"
              rarity="UNIQUE"
              greaterAffixes={5}
              image=""
              implicits={[]}
              affixes={[]}
              effect=""
              levelRequirement={2}
              classType="SORCERER"
              gameMode="ETERNAL_HARDCORE"
              power={2}
              typeName=""
              price="2M"
              user={{
                id: "",
                name: "Nextor",
                online: true,
                isCurrentUser: false,
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
