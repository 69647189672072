import { cn } from "../../utils/tailwind";
import { GameModeType } from "@repo/backend/schemas";
import { Image } from "../image";

export function GameMode({
  mode,
  width = 24,
  height = 24,
  className,
}: {
  mode: GameModeType;
  width?: number;
  height?: number;
  className?: string;
}) {
  const imageSrc: Record<GameModeType, string> = {
    SEASONAL_HARDCORE: "/ui/modes/seasonal_hardcore.webp",
    SEASONAL_SOFTCORE: "/ui/modes/seasonal_softcore.webp",
    ETERNAL_HARDCORE: "/ui/modes/eternal_hardcore.png",
    ETERNAL_SOFTCORE: "/ui/modes/eternal_softcore.png",
  };

  const capitalized = mode
    .replace("_", " ")
    .toLowerCase()
    .replace(/\b\w/g, (letter) => letter.toUpperCase());

  return (
    <div className="flex justify-center items-center gap-2">
      <Image alt="image" height={height} src={imageSrc[mode]} width={width} />
      <p className={cn("block text-start text-xs font-diablo", className)}>
        {capitalized}
      </p>
    </div>
  );
}
